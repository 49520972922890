<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button type="primary" @click="add" icon="el-icon-plus"
              >新增</el-button
            >
          </el-col>
        </el-row>
        <div></div>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="id"
        label="品牌图片"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="品牌名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="品牌简介"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="品牌关联商品"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="创建时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="id" label="操作" fixed="right" width="180px">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-tickets"
            plain
            @click="$router.push({ name: '', query: { id: scope.row.id } })"
            >查看
          </el-button>
          <el-button type="danger" icon="el-icon-del" plain> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    orderStatus: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      row: "",
      selectId: "",
      where: {
        pageNum: 0,
        pageSize: 0,
        keyWord: "",
      },
    };
  },
  methods: {
    listData() {
      this.where.pageSize = this.pageSize;
      this.where.limit = this.pageSize;
      this.where.shopId = this.shopId;
      this.where.page = this.currentPage;
      this.$post(this.$api.orderlist, this.where).then((res) => {
        if (res.code == 1) {
          this.totalData = res.data.count;
          this.list = res.data.data;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    exportData() {
      // console.log("exportData");
    },
    add() {
      this.$router.push({ name: "brandAdd" });
    },
  },
  created() {
    this.where.orderStatus = this.orderStatus == -1 ? "" : this.orderStatus;
    // this.listData();
  },
};
</script>
<style lang="scss" scoped>
// .admin_main_block_right {
//     /*width: 14%;*/
// }

.admin_main_block_right .el-row {
  width: 100%;
}

.admin_main_block_right div {
  float: left;
}
</style>

